import React from "react";
import Head from "../../components/head.js";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import { OutboundLink } from "gatsby-plugin-google-analytics";

import Layout from "../../components/layout";

const Jes = () => {
  return (
    <Layout>
      <Head title="Jes Goodwin" />
      <h3>Jes Goodwin</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Nottingham, England
      </h4>
      <p>
        <OutboundLink href="https://www.linkedin.com/in/jes-goodwin-09b135115/?originalSubdomain=uk">
          LinkedIn
        </OutboundLink>
      </p>
      {/* <p>
        <OutboundLink href="https://gamesworkshop.fandom.com/wiki/Jes_Goodwin">
          Games Workshop Fandom
        </OutboundLink>
      </p> */}

      <img
        src="https://www.warhammer.com/app/resources/catalog/product/920x950/99120104023_WaveSerpentNEW_02.jpg?fm=webp&w=920&h=948"
        alt="Jes Goodwin Art Eldar"
      />

      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/Pg7JUtnUT6Y?si=QIllHIgNP35Xtu6F"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </Layout>
  );
};

export default Jes;
